import { Modal, Drawer } from 'flowbite';
import autoComplete from "@tarekraafat/autocomplete.js";
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

let headerMenus, dropdownLinks, branchLinks, services, mobileDrawer, appointmentModal
let slotData, specialistServices, branch, clinic, appointmentType, appointmentAutoComplete

window.addEventListener('turbo:load', (e) => {
  initializeMenuNodes()
  removeAlert()

  if (document.getElementById('appointmentAutoComplete')) {
    initAppointmentSearch();
    initAppointmentFormListeners();
  }
})

const initializeMenuNodes = () => {
  headerMenus = document.querySelectorAll('.header-menu');
  dropdownLinks = document.querySelectorAll('.dropdown-link')
  branchLinks = document.querySelectorAll('.branch-link')
  services = document.querySelectorAll('.services .service')
}

const removeAlert = () => {
  const alertNode = document.querySelector('div[role="alert"]');
  if (alertNode) {
    setTimeout(() => {
      alertNode.remove();
    }, 8000);
  }
}

function _getSpecialistCard(specialist_id) {
  return fetch(window.Routes.ajax_specialist_card_path() + '?' + new URLSearchParams({
    specialist_id
  }), {
    method: 'GET'
  });
}

function _getSpecialistFreeSlots(specialist_id, clinic_id) {
  return fetch(window.Routes.ajax_specialist_free_slots_path() + '?' + new URLSearchParams({
    specialist_id,
    clinic_id
  }), {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });
}

function _getSpecialistServices(specialist_id, branch_id, appointmentType) {
  return fetch(window.Routes.ajax_specialist_services_path() + '?' + new URLSearchParams({
    specialist_id,
    branch_id,
    query: appointmentType == 1 ? 'Первичный приём' : 'Повторный приём'
  }), {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });
}

const deselectAllDates = (current) => {
  document.querySelectorAll('.slot-date').forEach((el) => {
    if (el !== current) {
      el.classList.remove('active');
    }
  })
}

const deselectAllTimes = (current) => {
  document.querySelectorAll('.slot-time').forEach((el) => {
    if (el !== current) {
      el.classList.remove('active');
    }
  })
}

const deselectAllServices = (current) => {
  document.querySelectorAll('.specialist-service').forEach((el) => {
    if (el !== current) {
      el.classList.remove('active');
    }
  })
}

const initSlider = () => {
  const swiper = new Swiper('#specialist-schedule', {
    slidesPerView: 'auto',
    modules: [Navigation],
    navigation: {
      nextEl: '.schedule-button-next',
      prevEl: '.schedule-button-prev',
    },
  });

  document.getElementById('specialist-schedule-controls').classList.remove('hidden')
}

const resetForm = (makeFormReset = true) => {
  if (makeFormReset) {
    document.getElementById('appointment-form').reset();
  }
  clinic = document.getElementById('clinic').value;
  branch = document.getElementById('branch').value;
  appointmentType = document.getElementById('appointment_type').value;
  document.getElementById('specialist-card-full').innerHTML = '';
  document.getElementById('selected_date-error').innerHTML = '';
  document.getElementById('selected_time-error').innerHTML = '';
  document.getElementById('specialist-card-full').classList.add('hidden');
  document.getElementById('search-specialists').innerHTML = '';
  document.getElementById('search-specialists').classList.add('hidden');
  document.getElementById('specialist-schedule').innerHTML = '';
  document.getElementById('specialist-services').innerHTML = '';
  document.getElementById('specialist-schedule-slots').innerHTML = '';
  document.getElementById('patient-data').classList.add('hidden');
  document.getElementById('no-appointments').classList.add('hidden');
  document.getElementById('specialist-schedule-controls').classList.add('hidden');
}

document.addEventListener('click', (e) => {
  const target = e.target;

  if (target.classList.contains('dropdown-link')) {
    const menu = target.nextElementSibling

      if (menu) {
        closeAllMenus(menu)
        menu.classList.toggle('active')
      }
  } else if (target.classList.contains('branch-link')) {
    const branchId = target.dataset.id;

    branchLinks.forEach((i) => {i.classList.remove('active')})

    target.classList.add('active')
    services.forEach((s) => {
      if (s.dataset.branch === branchId) {
        s.classList.add('active')
      } else {
        s.classList.remove('active')
      }
    })
  }

  let slotDateNode;

  if (target.classList.contains('slot-date')) {
    slotDateNode = target;
  }
  if (target.classList.contains('slot-wday')) {
    slotDateNode = target.parent;
  }

  if (slotDateNode) {
    deselectAllDates(slotDateNode);
    slotDateNode.classList.toggle('active');
    const
      slotDataEl = slotData.find((el) => {
        return el.date === slotDateNode.dataset.date
      }),
      slotTimes = slotDataEl.slots;

      let html = '';

      slotTimes.forEach(st => {
        html = html + `
          <div class='slot-time' data-time='${st.time}' data-timefull='${st.time_full}'>
            ${st.time}
          </div>
        `
      });

       document.getElementById('specialist-schedule-slots').innerHTML = html;
       document.getElementById('selected_date').value = slotDateNode.dataset.datefull;
  }

  if (target.classList.contains('slot-time')) {
    deselectAllTimes(target);
    target.classList.toggle('active');
    document.getElementById('selected_time').value = target.dataset.timefull;
  }

  if (target.classList.contains('specialist-service')) {
    deselectAllServices(target);
    target.classList.add('active');
    document.getElementById('selected_service').value = target.dataset.service;
  }

  if (target.id === 'create-appointment') {
    const
      modalEl = document.getElementById('appointment-modal'),
      options = {
          closable: true,
          onHide: () => {
            delete modalEl.dataset.specialist;
            document.getElementById('appointment-search').classList.remove('hidden');
            resetForm();
          },
          onShow: async () => {
            modalEl.dataset.specialist = target.dataset.specialist;
            document.getElementById('appointment-search').classList.add('hidden');

            const
              branch = document.getElementById('branch').value,
              clinic = document.getElementById('clinic').value,
              appointmentType = document.getElementById('appointment_type').value,
              spec_id = target.dataset.specialist,
              response = await _getSpecialistCard(spec_id),
              data = await response.text();

            if (data) {
              document.getElementById('specialist-card-full').innerHTML = data;
              document.getElementById('specialist-card-full').classList.remove('hidden');
              document.getElementById('search-specialists').classList.add('hidden');
              document.getElementById('selected_specialist').value = spec_id;

              const
                slotResponse = await _getSpecialistFreeSlots(spec_id, clinic),
                servicesResponse = await _getSpecialistServices(spec_id, branch, appointmentType);

              slotData = await slotResponse.json();
              specialistServices = await servicesResponse.json();

              if (slotData.length && specialistServices.length) {
                let html = `
                  <div class='swiper-wrapper'>
                `;

                slotData.forEach(sd => {
                  html = html + `
                    <div class='slot-date swiper-slide' data-date='${sd.date}' data-datefull='${sd.date_full}'>
                      <div class='slot-wday'>${sd.wday}</div>
                      ${sd.date}
                    </div>
                  `
                });

                document.getElementById('no-appointments').classList.add('hidden');
                document.getElementById('patient-data').classList.remove('hidden');
                document.getElementById('specialist-schedule').innerHTML = html;
                initSlider();

                if (specialistServices) {
                  let html = '';

                  specialistServices.forEach((ss, idx) => {
                    html = html + `
                      <div class='specialist-service ${idx === 0 ? 'active' : ''}' data-service='${ss.id}'>
                        ${ss.name}
                      </div>
                    `
                  });

                  console.log('here');


                  document.getElementById('specialist-services').innerHTML = html;
                  if (document.querySelector('.specialist-service.active')) {
                    document.getElementById('selected_service').value = document.querySelector('.specialist-service.active').dataset.service;
                  }
                }
              } else {
                document.getElementById('patient-data').classList.add('hidden');
                document.getElementById('specialist-schedule-controls').classList.add('hidden');
                document.getElementById('specialist-schedule-slots').innerHTML = '';
                document.getElementById('specialist-schedule').innerHTML = '';
                document.getElementById('specialist-services').innerHTML = '';
                document.getElementById('no-appointments').classList.remove('hidden');
              }
            }
          }
      };

    appointmentModal = new Modal(modalEl, options);

    appointmentModal.show();
  }

  if (target.id === 'appointment-button') {
    const
      modalEl = document.getElementById('appointment-modal'),
      options = {
          closable: true,
          onHide: () => {
            resetForm();

          },
          onShow: () => {
            resetForm();
          }
      };

    appointmentModal = new Modal(modalEl, options);

    appointmentModal.show();
  }

  if (target.classList.contains('mobile-menu-item')) {
    mobileDrawer.hide();
  }

  if (target.id === 'mobile-appointment-button') {
    const
      modalEl = document.getElementById('appointment-modal'),
      options = {
          closable: true,
          onHide: () => {
            resetForm();
          },
          onShow: () => {
            resetForm();
            mobileDrawer.hide();
          }
      };

    appointmentModal = new Modal(modalEl, options);

    appointmentModal.show();
  }

  if (target.id === 'close-appointment-modal') {
    appointmentModal.hide();
  }

  if (target.id === 'mobile-menu-button') {
    const
      drawerEl = document.getElementById('mobile-menu-drawer'),
      options = {
        placement: 'left',
        backdrop: true,
      };

    mobileDrawer = new Drawer(drawerEl, options);

    mobileDrawer.show();

  }

  if (target.id === 'close-menu-drawer') {
    mobileDrawer.hide();
  }
})

const closeAllMenus = (current) => {
  headerMenus.forEach((el) => {
    if (el !== current) {
      el.classList.remove('active');
    }
  })
}

window.addEventListener('click', (e) => {
  if (![...headerMenus, ...dropdownLinks, ...services, ...branchLinks].includes(e.target)) {
    closeAllMenus()
  }
})

function _getSpecialistsByService(service_id, clinic_id) {
  return fetch(window.Routes.ajax_specialists_by_service_path() + '?' + new URLSearchParams({
    service_id,
    clinic_id
  }), {
    method: 'GET'
  });
}

function _getAppointmentSearchResults(query) {
  return fetch(window.Routes.ajax_appointment_search_path() + '?' + new URLSearchParams({
    search_query: query,
    branch_id: branch,
    clinic_id: clinic
  }), {
    method: 'GET',
    headers: {
      Accept: 'application/json'
    }
  });
}

function _createPopup(text, type = 'notice') {
  document.getElementById('appointment-alert').innerHTML = `
    <div class="flex items-center w-full max-w-xs p-4 text-gray-800 bg-white rounded-lg shadow error ${type}" role="alert">
      <div class="mr-2 text-sm font-normal">
        ${text}
      </div>
    </div>
  `;
  removeAlert();
}

function _clearAllErrorMessages() {
  ['consent', 'name', 'patient_phone', 'selected_date', 'selected_time'].forEach((el) => {
    const errorEl = document.getElementById(`${el}-error`);
    if (errorEl) {
      errorEl.innerHTML = ''
    }
  })
}

async function _submitForm() {
  const response = await fetch(window.Routes.secure_form_appointment_form_path(), {
    method: 'POST',
    body: new FormData(document.getElementById('appointment-form')),
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    },
  });

  if (response.ok) {
    const json = await response.json();
    if (json.status === 'ok') {
      _createPopup('Вы успешно записались к специалисту, ожидайте звонка из клиники для подтверждения записи');
      appointmentModal.hide();

    } else {
      console.log(json.errors);
      _clearAllErrorMessages();
      for (const [key, value] of Object.entries(json.errors)) {
        const errorEl = document.getElementById(`${key}-error`);
        if (errorEl) {
          if (key === 'selected_date') {
            errorEl.innerHTML = 'выберите желаемую дату'
          } else if (key === 'selected_time') {
            errorEl.innerHTML = 'выберите желаемое время'
          } else {
            errorEl.innerHTML = value.join(', ')
          }
        }
      }
    }
  } else {
    _createPopup('Не удалось записаться к специалисту, пожалуйста запишитесь по телефону через регистратуру', 'alert');
    appointmentModal.hide();
  }
}

const initAppointmentFormListeners = () => {
  document.getElementById('clinic').addEventListener('change', (e) => {
    clinic = e.target.value;
    resetForm(false);
  });

  document.getElementById('branch').addEventListener('change', (e) => {
    branch = e.target.value;
    resetForm(false);
  });

  document.getElementById('appointment_type').addEventListener('change', (e) => {
    appointmentType = e.target.value;
    resetForm(false);
  });

  document.getElementById('appointment-submit').addEventListener('click', (e) => {
    _submitForm();
  });
}

const initAppointmentSearch = () => {
  clinic = document.getElementById('clinic').value;
  branch = document.getElementById('branch').value;
  appointmentType = document.getElementById('appointment_type').value,

  appointmentAutoComplete = new autoComplete({
    selector: "#appointmentAutoComplete",
    placeHolder: "Введите название услуги или имя врача",
    debounce: 500,
    threshold: 3,
    data: {
      src: async(query) => {
        try {
          const
            response = await _getAppointmentSearchResults(query),
            data = await response.json()

          data.forEach((el) => {
            el.searchable = query
          })

          return data
        } catch (error) {
          return error;
        }
      },
      keys: ['searchable'],
    },
    resultsList: {
        element: (list, data) => {
            list.id = "search-results"
            list.classList.add('shadowed')
            const categories = data.results.reduce((acc, el) => {
              if (!acc.includes(el.value.type)) {
                acc.push(el.value.type)
              }
              return acc
            }, [])
            categories.forEach(category => {
              const cat = document.createElement("div");
              const catTitle = document.createElement("h2");
              cat.setAttribute("id", category);
              catTitle.innerHTML = category;
              cat.appendChild(catTitle)
              list.prepend(cat);

              data.results.forEach((el) => {
                if (el.value.type === category) {
                  const li = document.createElement('li');
                  li.innerHTML = `<div class="search-item" data-id="${el.value.link}">${el.value.title}</div>`;
                  cat.appendChild(li)
                  li.addEventListener('click', async () => {
                    if (el.value.link.includes('service_id')) {
                      document.getElementById('specialist-schedule-slots').innerHTML = '';
                      document.getElementById('specialist-schedule').innerHTML = '';
                      document.getElementById('specialist-services').innerHTML = '';
                      document.getElementById('specialist-schedule-controls').classList.add('hidden');
                      document.getElementById('patient-data').classList.add('hidden');

                      const
                        response = await _getSpecialistsByService(el.value.link.replace('service_id:', ''), clinic),
                        data = await response.text();

                        if (data) {
                          document.getElementById('search-specialists').innerHTML = data;
                          document.getElementById('search-specialists').classList.remove('hidden');
                          document.getElementById('specialist-card-full').classList.add('hidden');
                          document.querySelectorAll('.specialist-card.mini').forEach(node => {
                            node.addEventListener('click', async (e) => {
                              let target;

                              if (!e.target.classList.contains('specialist-card')) {
                                target = e.target.closest('.specialist-card');
                              } else {
                                target = e.target;
                              }

                              const
                                spec_id = target.dataset['specialistId'],
                                response = await _getSpecialistCard(spec_id),
                                data = await response.text();

                              if (data) {
                                document.getElementById('specialist-card-full').innerHTML = data;
                                document.getElementById('specialist-card-full').classList.remove('hidden');
                                document.getElementById('search-specialists').classList.add('hidden');
                                document.getElementById('selected_specialist').value = spec_id;

                                const
                                  slotResponse = await _getSpecialistFreeSlots(spec_id, clinic),
                                  servicesResponse = await _getSpecialistServices(spec_id, branch, appointmentType);

                                slotData = await slotResponse.json();
                                specialistServices = await servicesResponse.json();

                                if (slotData.length && specialistServices.length) {
                                  let html = `
                                    <div class='swiper-wrapper'>
                                  `;

                                  slotData.forEach(sd => {
                                    html = html + `
                                      <div class='slot-date swiper-slide' data-date='${sd.date}' data-datefull='${sd.date_full}'>
                                        <div class='slot-wday'>${sd.wday}</div>
                                        ${sd.date}
                                      </div>
                                    `
                                  });

                                  document.getElementById('no-appointments').classList.add('hidden');
                                  document.getElementById('patient-data').classList.remove('hidden');
                                  document.getElementById('specialist-schedule').innerHTML = html;
                                  initSlider();

                                  if (specialistServices) {
                                    let html = '';

                                    specialistServices.forEach((ss, idx) => {
                                      html = html + `
                                        <div class='specialist-service ${idx === 0 ? 'active' : ''}' data-service='${ss.id}'>
                                          ${ss.name}
                                        </div>
                                      `
                                    });

                                    document.getElementById('specialist-services').innerHTML = html;
                                    if (document.querySelector('.specialist-service.active')) {
                                      document.getElementById('selected_service').value = document.querySelector('.specialist-service.active').dataset.service;
                                    }
                                  }
                                } else {
                                  document.getElementById('patient-data').classList.add('hidden');
                                  document.getElementById('specialist-schedule-controls').classList.add('hidden');
                                  document.getElementById('specialist-schedule-slots').innerHTML = '';
                                  document.getElementById('specialist-schedule').innerHTML = '';
                                  document.getElementById('specialist-services').innerHTML = '';
                                  document.getElementById('no-appointments').classList.remove('hidden');
                                }
                              };
                            })
                          });
                        }
                    } else {
                      document.getElementById('specialist-schedule-slots').innerHTML = '';
                      document.getElementById('specialist-schedule').innerHTML = '';
                      document.getElementById('specialist-services').innerHTML = '';
                      document.getElementById('specialist-schedule-controls').classList.add('hidden');
                      document.getElementById('patient-data').classList.add('hidden');

                      const
                        spec_id = el.value.link.replace('specialist_id:', ''),
                        response = await _getSpecialistCard(spec_id),
                        data = await response.text();

                      if (data) {
                        document.getElementById('specialist-card-full').innerHTML = data;
                        document.getElementById('specialist-card-full').classList.remove('hidden');
                        document.getElementById('search-specialists').classList.add('hidden');
                        document.getElementById('selected_specialist').value = spec_id;

                        const
                          slotResponse = await _getSpecialistFreeSlots(spec_id, clinic),
                          servicesResponse = await _getSpecialistServices(spec_id, branch, appointmentType);

                        slotData = await slotResponse.json();
                        specialistServices = await servicesResponse.json();

                          if (slotData.length && specialistServices.length) {
                            let html = `
                              <div class='swiper-wrapper'>
                            `;

                            slotData.forEach(sd => {
                              html = html + `
                                <div class='slot-date swiper-slide' data-date='${sd.date}' data-datefull='${sd.date_full}'>
                                  <div class='slot-wday'>${sd.wday}</div>
                                  ${sd.date}
                                </div>
                              `
                            });

                            document.getElementById('no-appointments').classList.add('hidden');
                            document.getElementById('patient-data').classList.remove('hidden');
                            document.getElementById('specialist-schedule').innerHTML = html;
                            initSlider();

                            if (specialistServices) {
                              let html = '';

                              specialistServices.forEach((ss, idx) => {
                                html = html + `
                                  <div class='specialist-service ${idx === 0 ? 'active' : ''}' data-service='${ss.id}'>
                                    ${ss.name}
                                  </div>
                                `
                              });

                              document.getElementById('specialist-services').innerHTML = html;
                              if (document.querySelector('.specialist-service.active')) {
                                document.getElementById('selected_service').value = document.querySelector('.specialist-service.active').dataset.service;
                              }
                            }
                          } else {
                            document.getElementById('patient-data').classList.add('hidden');
                            document.getElementById('specialist-schedule-controls').classList.add('hidden');
                            document.getElementById('specialist-schedule-slots').innerHTML = '';
                            document.getElementById('specialist-schedule').innerHTML = '';
                            document.getElementById('specialist-services').innerHTML = '';
                            document.getElementById('no-appointments').classList.remove('hidden');
                          }
                      }
                    }
                  })
                }
              })
            });
            if (!data.results.length) {
              list.innerHTML = `<span>Ничего не найдено</span>`;
            }

        },
        noResults: true,
        maxResults: 125
    },
    resultItem: {
      element: (item, data) => {
        item.remove()
      },
    },
    events: {
        input: {
            selection: (event) => {},
            focus: () => {
              const inputValue = appointmentAutoComplete.input.value;

              if (inputValue.length) appointmentAutoComplete.start();
          },
        }
    }
  });
}